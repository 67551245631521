.header-class {
  display: flex;
  flex-direction: row-reverse;
}
.heading-shadow b {
  font-size: 21px;
  font-family: A_black !important;
  letter-spacing: 1px;
}

.grid p {
  font-size: 16px;
  margin-bottom: 32px;
  font-family: A_heavy !important;
}

.offerSection.grid {
 padding:50px 0;
}
.offerInner-box
{
  display: flex;
  width: 100%;
  text-align: center;
  flex-flow: column;
}
.image-top {
  margin-top:7%;
}
.image-top h4 {
  color: #fff !important;
  font-family: A_black !important;
  letter-spacing: 1px;
}

h3 {
  font-weight: 600 !important;
}

.bottom-content {
  margin-top:7%;
  margin-bottom: 10%;
  text-align: center;
}
.bottom-content p
{
   font-size: 18px;
  font-family: A_heavy !important;
  letter-spacing: 1px;

}
.body {
  overflow-x: hidden;
}
.top-text {
  font-size: 23pt;
  font-family: A_black !important;
  letter-spacing: 1px;
  font-weight: 900;
}

.incubation-img {
  height: auto;
  width: auto;
}
.intel-card {
  margin-top: -1.4rem;
}
.logo
{
  padding:16px 0;
  text-align: right;
}
.logo img
{
  max-width: 180px;
}
.pageContainer
{
  padding:0 32px;
}
.footer-distributed a:hover
{
  text-decoration: none;
}
.to-do.grid h2 b
{
  font-size: 24px;
}
.image-top h2
{
    font-size: 50px;
    text-transform: capitalize;
    
    font-family: A_black !important;
    font-weight: bold;
    letter-spacing: 3px;
}

@media screen and (max-width: 1023px) {
h1.title-text
{
font-size: 40px;
margin-bottom: 7.5%;
}
.image-top h2
{
    font-size: 36px;
}
.content-text
{
  font-size: 16px;
}

}
@media screen and (max-width: 767px) {
  .logo
  {
    margin-bottom: 80px;
  }

.image-top {
  margin-top: 22%;
}
  .content-text
  {
    font-size: 14px;
  }
  
  .footer-distributed
  {
    height:auto;
    padding:16px 12px;
    display: flex;
    flex-flow: column;
  }
  .footer-distributed a
  {
    margin: 8px 0;
    font-size: 16px;
    text-decoration: none;

  }
  .heading-shadow {
    text-shadow: none;
}
.to-do.grid h2 b
{
  font-size: 20px;
}
.to-do.grid {
  padding: 124px 0;
}
.logo img {
  max-width: 150px;
}
.incubation-img
{
  margin-top: 16px;
}
  }
@media screen and (max-width: 500px) {
  h1.title-text
{
font-size: 24px;
margin-bottom: 7.5%;
}
.image-top h2
{
    font-size: 24px;
}
  .intel-card {
    margin-top: 0;
  }
  .to-do.grid {
    padding: 94px 0;
}

}
