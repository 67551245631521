p {
    /* font-family: "itc-book" !important; */
    font-family: A_heavy !important;
}
h1,h2,h3,h4,h5,h6 {
    /* font-family: "itc-bold" !important; */
    font-family: A_heavy !important;
}
.heading-top{
    margin-top: 12%;
}
.body-text{
    margin-top: 10%;
    margin-bottom: 10%;
}

.header-class{
    display: flex;
    flex-direction: row-reverse;
}
h1,h3,h4{
    color: #000000 !important;
}
h3{
    font-weight: 600 !important;
}
.p-text{
    font-size: 27 !important;
}
.h1-text{
    font-size: 64 !important;
    font-weight: bold;
}
a{ 
    color: #3b8c97;
    cursor: pointer;
}
a:hover{
    color: rgba(241,90,36,1);
    text-decoration: none;
} 
.body-text .h5{
    font-size: 31 !important;
}
.top-text{
 cursor: pointer;
}
b {
    /* font-family: "itc-bold" !important; */
    font-family: A_heavy !important;
}