@font-face {
  font-family: A_medium;
  src: url("./myfonts/3AEDDF_0_0.ttf");
}

@font-face {
  font-family: A_book;
  src: url("./myfonts/3AEDDF_1_0.ttf");
}

@font-face {
  font-family: A_black;
  src: url("./myfonts/3AEDDF_2_0.ttf");
}

@font-face {
  font-family: A_light;
  src: url("./myfonts/3AEDDF_3_0.ttf");
}

@font-face {
  font-family: A_heavy;
  src: url("./myfonts/3AEDDF_4_0.ttf");
}

@font-face {
  font-family: A_bold;
  src: url("./myfonts/3AEDDF_5_0.ttf");
}

* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
body {
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #fff;
}
.h1-text {
  font-family: A_heavy !important;
}

.mini-text {
  font-family: A_heavy !important;
  color: black !important;
}
