p {
  /* font-family: "itc-book" !important; */
  font-family: A_heavy !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "itc-bold" !important; */
  font-family: A_heavy !important;
}
.header-top {
  background-color: chocolate;
  height: 400px;
}
.text-color {
  color: white;
}
.heading-text {
  text-align: center;
}
.version-text {
  margin-top: 8%;
  margin-bottom: 10%;
  text-align: center;
}
.body-text {
  width: 49%;
  margin-left: auto;
  margin-right: auto;
}
.privacy-text {
  margin-top: 10%;
  text-align: center;
  margin-bottom: 13%;
}
h3 {
  color: #000000 !important;
  font-weight: 600 !important;
}
h4 {
  color: #000000 !important;
  font-size: 25px !important;
  line-height: 30px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
}
.body-text a {
  color: #3b8c97;
  cursor: pointer;
}
a:hover {
  color: rgba(241, 90, 36, 1);
  text-decoration: none;
}
.body-text p {
  color: #303030 !important;
  font-size: 18px !important;
}
.lower-section {
  margin-top: 15%;
  margin-bottom: 15%;
}

.lower-section-heading {
  margin-top: 5%;
  margin-bottom: 7%;
}
.elementor-heading-title {
  color: #000000 !important;
  font-size: 2rem;
  text-transform: uppercase !important;
}
.span-text {
  color: #000000;
  font-size: 20pt;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.h4-text {
  margin-top: 7%;
}
.header-class {
  display: flex;
  flex-direction: row-reverse;
  padding: 16px;
}
.text-heading {
  margin-top: 15%;
}
.body {
  width: 100%;
}
.top-text {
  cursor: pointer;
}
li {
  /* font-family: "itc-book" !important; */
  font-family: A_heavy !important;
}