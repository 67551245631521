.footer-distributed {
 
  padding: 1.5rem 0;
  bottom: 0px;
  overflow-y: hidden;
  margin: 0;
}
.footer-distributed a {
  font-family: A_heavy !important;
}
.text-color {
  color: white;
}
.footer {
 
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
}
.text-size {
  margin-right: 12%;
}
.footer a:hover{
  color: #333;
}