.bannerSection
{
    background-image: url("../Images/banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
}
.logo
{
  padding:16px;
  text-align: right;
}
.logo img
{
  max-width: 180px;
}
.webTopText
{
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
}
.title-text {
  font-size: 50px;
  text-transform: capitalize;
  margin: 15% 0 5% 0;
  color: #fff !important;
  font-family: A_black !important;
  font-weight: bold;
  letter-spacing:2px;
  text-transform: uppercase;

}
.content-text {
  font-size: 18px;
  font-family: A_heavy !important;
  letter-spacing: 1px;
   color: #fff;
}

@media screen and (max-width: 1023px) {
.bannerSection
{
    height:550px
}

}